import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0b6ead47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "degree-container" }
const _hoisted_2 = { class: "degree-icon" }
const _hoisted_3 = { class: "degree-info" }
const _hoisted_4 = { class: "degree-title" }
const _hoisted_5 = { class: "degree" }
const _hoisted_6 = { class: "degree-institution" }
const _hoisted_7 = { class: "degree-interval" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_svg_icon, {
        path: _ctx.icon,
        type: "mdi"
      }, null, 8, ["path"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.degree), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.institution), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.interval), 1)
    ])
  ]))
}