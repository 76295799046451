import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b19c7ebe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "contato",
  class: "Wrapper"
}
const _hoisted_2 = { class: "C-Container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { id: "contact" }
const _hoisted_6 = {
  key: 0,
  for: "name"
}
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = {
  key: 1,
  for: "email"
}
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 2,
  for: "message"
}
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "contact-submit" }
const _hoisted_13 = ["data-callback", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "contact-title",
        innerHTML: _ctx.content.content.contact.title
      }, null, 8, _hoisted_3),
      _createElementVNode("p", {
        class: "contact-intro",
        innerHTML: _ctx.content.content.contact.intro
      }, null, 8, _hoisted_4),
      _createElementVNode("form", _hoisted_5, [
        (_ctx.errors.name)
          ? (_openBlock(), _createElementBlock("label", _hoisted_6, "O Nome deve ser preenchido"))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass({ 'input-error': _ctx.errors.name }),
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
          id: "name",
          placeholder: _ctx.content.content.form.name,
          name: "name"
        }, null, 10, _hoisted_7), [
          [_vModelText, _ctx.form.name]
        ]),
        (_ctx.errors.email)
          ? (_openBlock(), _createElementBlock("label", _hoisted_8, "Email inválido"))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass({ 'input-error': _ctx.errors.email }),
          id: "email",
          placeholder: _ctx.content.content.form.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
          name: "email",
          type: "email"
        }, null, 10, _hoisted_9), [
          [_vModelText, _ctx.form.email]
        ]),
        (_ctx.errors.message)
          ? (_openBlock(), _createElementBlock("label", _hoisted_10, "Preencha sua mensagem"))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("textarea", {
          class: _normalizeClass({ 'input-error': _ctx.errors.message }),
          id: "message",
          placeholder: _ctx.content.content.form.message,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.message) = $event)),
          name: "mensagem"
        }, null, 10, _hoisted_11), [
          [_vModelText, _ctx.form.message]
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("input", {
            class: "g-recaptcha",
            "data-sitekey": "6LfgPSQfAAAAAHrI0m6xklgKPhs_k5JGeSy6XoUS",
            "data-callback": _ctx.submit,
            "data-action": "submit",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
            type: "submit",
            value: 
              _ctx.sending
                ? _ctx.content.content.form.button.sending
                : _ctx.content.content.form.button.send
            
          }, null, 8, _hoisted_13)
        ])
      ])
    ])
  ]))
}