import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1135f2e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "experiencia",
  class: "Wrapper"
}
const _hoisted_2 = { class: "F-Container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "F-Wrapper" }
const _hoisted_5 = { class: "Formation-Container" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "formation-degress" }
const _hoisted_8 = { class: "Skills-Container" }
const _hoisted_9 = { class: "E-Container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DegreeComponent = _resolveComponent("DegreeComponent")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_SkillComponent = _resolveComponent("SkillComponent")!
  const _component_ExperienceCompoent = _resolveComponent("ExperienceCompoent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "formation-title",
        innerHTML: _ctx.content.content.formation.title
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            class: "formation-intro",
            innerHTML: _ctx.content.content.formation.intro
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_swiper, {
              "slides-per-view": 1.5,
              "slides-offset-after": 0,
              "slides-offset-before": 0,
              "space-between": 0,
              loop: false,
              breakpoints: _ctx.bformation
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.formations, (formation) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, {
                    key: formation.title
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DegreeComponent, {
                        title: formation.title,
                        institution: formation.institution,
                        degree: formation.degree,
                        interval: `${formation.start_date.getFullYear().toString()}${
                    formation.end_date
                      ? ' - ' + formation.end_date.getFullYear().toString()
                      : ''
                  }`,
                        icon: _ctx.icons[formation.icon]
                      }, null, 8, ["title", "institution", "degree", "interval", "icon"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["breakpoints"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.content.skills, (skill) => {
            return (_openBlock(), _createBlock(_component_SkillComponent, {
              key: skill.title,
              title: skill.title,
              highlight: skill.highlight,
              info: skill.info
            }, null, 8, ["title", "highlight", "info"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_swiper, {
          "slides-per-view": 1.35,
          "slides-offset-after": 25,
          "slides-offset-before": 0,
          "space-between": 20,
          breakpoints: _ctx.breakpoints
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.experiences, (exp) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: exp.company
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ExperienceCompoent, {
                    company: exp.company,
                    description: exp.description,
                    interval: `${exp.start_date.getFullYear().toString()}${
                exp.end_date
                  ? ' - ' + exp.end_date.getFullYear().toString()
                  : ' - Atual'
              }`,
                    role: exp.role,
                    techs: exp.techs.map((elem) => elem.title)
                  }, null, 8, ["company", "description", "interval", "role", "techs"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["breakpoints"])
      ])
    ])
  ]))
}