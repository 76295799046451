import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5bb86ac6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "Wrapper",
  id: "projetos"
}
const _hoisted_2 = { class: "P-Contaier" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "projects-slide" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JobComponent = _resolveComponent("JobComponent")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "projects-title",
        innerHTML: _ctx.content.content.projects.title
      }, null, 8, _hoisted_3),
      _createElementVNode("p", {
        class: "projects-intro",
        innerHTML: _ctx.content.content.projects.intro
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_swiper, {
          modules: _ctx.modules,
          breakpoints: _ctx.breakpoints,
          "space-between": 15,
          "on-slide-change": _ctx.onSwipe
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.projects, (job) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: job.title
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_JobComponent, {
                    techs: job.techs.map((elem) => elem.title),
                    title: job.title,
                    year: job.year.toString(),
                    type: job.type
                  }, null, 8, ["techs", "title", "year", "type"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules", "breakpoints", "on-slide-change"])
      ])
    ])
  ]))
}