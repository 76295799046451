import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_HeaderSection = _resolveComponent("HeaderSection")!
  const _component_ProjectsSection = _resolveComponent("ProjectsSection")!
  const _component_FormationSection = _resolveComponent("FormationSection")!
  const _component_ContactSection = _resolveComponent("ContactSection")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.content.loaded)
      ? (_openBlock(), _createBlock(_component_LoadingOverlay, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.content.loaded)
      ? (_openBlock(), _createBlock(_component_HeaderSection, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.content.loaded)
      ? (_openBlock(), _createBlock(_component_ProjectsSection, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.content.loaded)
      ? (_openBlock(), _createBlock(_component_FormationSection, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.content.loaded)
      ? (_openBlock(), _createBlock(_component_ContactSection, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.content.loaded)
      ? (_openBlock(), _createBlock(_component_FooterSection, { key: 5 }))
      : _createCommentVNode("", true)
  ], 64))
}