import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f56e0efa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "experiece-container" }
const _hoisted_2 = { class: "experience-company" }
const _hoisted_3 = { class: "experience-role" }
const _hoisted_4 = { class: "experience-description" }
const _hoisted_5 = { class: "experience-interval" }
const _hoisted_6 = { class: "experience-techs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TechComponent = _resolveComponent("TechComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.company), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.role), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.description), 1),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.interval), 1),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.techs, (tech) => {
        return (_openBlock(), _createBlock(_component_TechComponent, {
          light: true,
          key: tech,
          title: tech
        }, null, 8, ["title"]))
      }), 128))
    ])
  ]))
}