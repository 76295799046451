import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["T-Container", {
      'tech-light': _ctx.light,
    }])
  }, [
    _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)
  ], 2))
}